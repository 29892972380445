import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import { post_fetch, post_fetch_download } from 'model/FetchManage'

import {
  GET_SALES_STAT,
  GET_LEAD_STAT,
  GET_INST_CUST_DETAIL,
  GET_INST_ACT_STAT,
  GET_INST_CUST_STAT,
  GET_LOGS_EXCEL_DOWNLOAD,
  GET_KEYWORD_BY_MONTHS,
  GET_KEYWORD_UNDER_CATEGORY,
  GET_ALL_KEYWORDS,
  GET_DOWNLOAD_KEYWORD_CHART
} from 'constants/actionTypes'

import {
  getsaleslogstat,
  getleadlogstat,
  getInstCustDetail,
  getInstActstat,
  getInstCuststat,
  getlogsdownload,
  getKeywordByMonths,
  getKeywordUnderCategory,
  getAllKeywords,
  getKeywordChartDownload
} from './actions'

import {
  loadingMessage,
  hideMessage,
} from '../../constants/commonFunc'

const cmm = require('constants/common');
const SALESLOG_STAT = '/saleslog/summary_saleslog'
const LEADLOG_STAT = '/saleslog/summary_saleslog_lead'
const INSTCUST_DETAIL = '/statistics/statis_acc'
const INSTACT_STAT = '/statistics/statis_instact'
const INSTCUST_STAT = '/statistics/statis_instcust'
const LOGS_EXCEL_DOWNLOAD = '/saleslog/list_saleslog_download'
const GET_CATEGORY = '/statistics/statis_cate'
const KEYWORD_UNDER_CATEGORY = '/statistics/statis_kword'
const ALL_KEYWORDS = '/statistics/statis_cate_kword'
const TEST_URL = 'http://172.30.1.23:5526'
const KEYWORD_CHART_DOWNLOAD = '/saleslog/keyword_chart_download'


function* _getKeywordChartDownload({ payload: { body } }) {
  try {
    const response = yield call(post_fetch_download, cmm.SERVER_API_URL + KEYWORD_CHART_DOWNLOAD, body)
    yield put(getKeywordChartDownload.success(response))

  } catch (error) {
    yield put(getKeywordChartDownload.error(error))
  }
}

function* _getAllKeywords({ payload: { body } }) {
  try {
    const response = yield call(post_fetch, cmm.SERVER_API_URL + ALL_KEYWORDS, body)
    yield put(getAllKeywords.success(response))

  } catch (error) {
    yield put(getAllKeywords.error(error))

  }
}

function* _getKeywordUnderCategory({ payload: { body } }) {
  try {
    const response = yield call(post_fetch, cmm.SERVER_API_URL + KEYWORD_UNDER_CATEGORY, body)
    yield put(getKeywordUnderCategory.success(response))
  }
  catch (error) {
    yield put(getKeywordUnderCategory.error(error))
  }
}
function* _getKeywordByMonths({ payload: { body } }) {
  try {
    const response = yield call(post_fetch, cmm.SERVER_API_URL + GET_CATEGORY, body)
    yield put(getKeywordByMonths.success(response))

  }
  catch (error) {
    yield put(getKeywordByMonths.error(error))
  }
}

function* _getSalesLogStat({ payload: { body } }) {
  try {
    yield loadingMessage()
    const response = yield call(post_fetch, cmm.SERVER_API_URL + SALESLOG_STAT, body)
    yield hideMessage()
    yield put(getsaleslogstat.success(response))

  } catch (error) {
    yield put(getsaleslogstat.error(error))
  }
}

function* _getLeadLogStat({ payload: { body } }) {
  try {
    yield loadingMessage()
    const response = yield call(post_fetch, cmm.SERVER_API_URL + LEADLOG_STAT, body)
    yield hideMessage()
    yield put(getleadlogstat.success(response))
  } catch (error) {
    yield put(getleadlogstat.error(error))
  }
}

function* _getInstCustDetail({ payload: { body } }) {
  try {
    yield loadingMessage()
    const response = yield call(post_fetch, cmm.SERVER_API_URL + INSTCUST_DETAIL, body)
    yield hideMessage()
    yield put(getInstCustDetail.success(response))
  } catch (error) {
    yield put(getInstCustDetail.error(error))
  }
}

function* _getInstActStat({ payload: { body } }) {
  try {
    yield loadingMessage()
    const response = yield call(post_fetch, cmm.SERVER_API_URL + INSTACT_STAT, body)
    yield hideMessage()
    yield put(getInstActstat.success(response))
  } catch (error) {
    yield put(getInstActstat.error(error))
  }
}

function* _getInstCustStat({ payload: { body } }) {
  try {
    yield loadingMessage()
    const response = yield call(post_fetch, cmm.SERVER_API_URL + INSTCUST_STAT, body)
    yield hideMessage()
    yield put(getInstCuststat.success(response))
  } catch (error) {
    yield put(getInstCuststat.error(error))
  }
}
function* _getLogsExcelDownload({ payload: { body } }) {
  try {
    yield loadingMessage()
    const response = yield call(post_fetch_download, cmm.SERVER_API_URL + LOGS_EXCEL_DOWNLOAD, body)
    yield hideMessage()
    yield put(getlogsdownload.success(response))
  } catch (error) {
    yield put(getlogsdownload.error(error))
  }
}


function* watchGetKeywordByMonths() {
  yield takeEvery(GET_KEYWORD_BY_MONTHS, _getKeywordByMonths)
}

function* watchGetSalesLogStat() {
  yield takeEvery(GET_SALES_STAT, _getSalesLogStat)
}

function* watchGetLeadLogStat() {
  yield takeEvery(GET_LEAD_STAT, _getLeadLogStat)
}
function* watchGetInstCustDetail() {
  yield takeEvery(GET_INST_CUST_DETAIL, _getInstCustDetail)
}
function* watchGetInstActStat() {
  yield takeEvery(GET_INST_ACT_STAT, _getInstActStat)
}

function* watchGetInstCustStat() {
  yield takeEvery(GET_INST_CUST_STAT, _getInstCustStat)
}

function* watchGetLogsExcelDownload() {
  yield takeEvery(GET_LOGS_EXCEL_DOWNLOAD, _getLogsExcelDownload)
}

function* watchGetKeywordUnderCategory() {
  yield takeEvery(GET_KEYWORD_UNDER_CATEGORY, _getKeywordUnderCategory)
}

function* watchGetAllKeywords() {
  yield takeEvery(GET_ALL_KEYWORDS, _getAllKeywords)
}

function* watchGetKeywordChartDownload() {
  yield takeEvery(GET_DOWNLOAD_KEYWORD_CHART, _getKeywordChartDownload)
}


function* dashboardSaga() {
  yield all([
    fork(watchGetSalesLogStat),
    fork(watchGetLeadLogStat),
    fork(watchGetInstCustDetail),
    fork(watchGetInstActStat),
    fork(watchGetInstCustStat),
    fork(watchGetLogsExcelDownload),
    fork(watchGetKeywordByMonths),
    fork(watchGetKeywordUnderCategory),
    fork(watchGetAllKeywords),
    fork(watchGetKeywordChartDownload)
  ])
}

export default dashboardSaga
