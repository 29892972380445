import {
  GET_SALES_STAT,
  GET_SALES_STAT_SUCCESS,
  GET_SALES_STAT_ERROR,
  GET_LEAD_STAT,
  GET_LEAD_STAT_SUCCESS,
  GET_LEAD_STAT_ERROR,
  GET_INST_CUST_DETAIL,
  GET_INST_CUST_DETAIL_SUCCESS,
  GET_INST_CUST_DETAIL_ERROR,
  GET_INST_ACT_STAT,
  GET_INST_ACT_STAT_SUCCESS,
  GET_INST_ACT_STAT_ERROR,
  GET_INST_CUST_STAT,
  GET_INST_CUST_STAT_SUCCESS,
  GET_INST_CUST_STAT_ERROR,
  GET_LOGS_EXCEL_DOWNLOAD,
  GET_LOGS_EXCEL_DOWNLOAD_SUCCESS,
  GET_LOGS_EXCEL_DOWNLOAD_ERROR,
  GET_KEYWORD_BY_MONTHS,
  GET_KEYWORD_BY_MONTHS_SUCCESS,
  GET_KEYWORD_BY_MONTHS_ERROR,
  GET_KEYWORD_UNDER_CATEGORY,
  GET_KEYWORD_UNDER_CATEGORY_SUCCESS,
  GET_KEYWORD_UNDER_CATEGORY_ERROR,
  GET_ALL_KEYWORDS,
  GET_ALL_KEYWORDS_SUCCESS,
  GET_ALL_KEYWORDS_ERROR,
  GET_DOWNLOAD_KEYWORD_CHART,
  GET_DOWNLOAD_KEYWORD_CHART_ERROR,
  GET_DOWNLOAD_KEYWORD_CHART_SUCCESS
} from '../../constants/actionTypes';

const initialState = {
  getsaleslogstatRes: null,
  getleadlogstatRes: null,
  getInstActstatRes: null,
  getInstCuststatRes: null,
  loading: true,
  getlogsdownloadRes: null,
  keywordResponse: null,
  keywordUnderCategoryResposnse: null,
  allKeywords: null,
  keywordChartDownloadResponse: null,
}

const Dashboard = (state = initialState, action) => {

  switch (String(action.type)) {

    case GET_SALES_STAT:
      return { ...state, loading: true }
    case GET_SALES_STAT_SUCCESS:
      return { ...state, getsaleslogstatRes: action.payload.response.message, loading: false }
    case GET_SALES_STAT_ERROR:
      return { ...state, getsaleslogstatRes: false, loading: false }

    case GET_LEAD_STAT:
      return { ...state, loading: true }
    case GET_LEAD_STAT_SUCCESS:
      return { ...state, getleadlogstatRes: action.payload.response.message, loading: false }
    case GET_LEAD_STAT_ERROR:
      return { ...state, getleadlogstatRes: false, loading: false }

    case GET_INST_CUST_DETAIL:
      return { ...state, loading: true }
    case GET_INST_CUST_DETAIL_SUCCESS:
      return { ...state, getInstCustDetailRes: action.payload.response.message, loading: false }
    case GET_INST_CUST_DETAIL_ERROR:
      return { ...state, getInstCustDetailRes: false, loading: false }

    case GET_INST_ACT_STAT:
      return { ...state, loading: true }
    case GET_INST_ACT_STAT_SUCCESS:
      return { ...state, getInstActstatRes: action.payload.response.message, loading: false }
    case GET_INST_ACT_STAT_ERROR:
      return { ...state, getInstActstatRes: false, loading: false }

    case GET_INST_CUST_STAT:
      return { ...state, loading: true }
    case GET_INST_CUST_STAT_SUCCESS:
      return { ...state, getInstCuststatRes: action.payload.response.message, loading: false }
    case GET_INST_CUST_STAT_ERROR:
      return { ...state, getInstCuststatRes: false, loading: false }

    case GET_LOGS_EXCEL_DOWNLOAD:
      return { ...state, loading: true }
    case GET_LOGS_EXCEL_DOWNLOAD_SUCCESS:
      return { ...state, getlogsdownloadRes: action.payload.response.message, loading: false }
    case GET_LOGS_EXCEL_DOWNLOAD_ERROR:
      return { ...state, getlogsdownloadRes: false, loading: false }


    case GET_KEYWORD_BY_MONTHS:
      return { ...state, loading: true }
    case GET_KEYWORD_BY_MONTHS_SUCCESS:
      return { ...state, loading: false, keywordResponse: action.payload.response.message }
    case GET_KEYWORD_BY_MONTHS_ERROR:
      return { ...state, loading: false, keywordResponse: null }

    case GET_KEYWORD_UNDER_CATEGORY:
      return { ...state, loading: true }
    case GET_KEYWORD_UNDER_CATEGORY_SUCCESS:
      return { ...state, loading: false, keywordUnderCategoryResposnse: action.payload.response.message }
    case GET_KEYWORD_UNDER_CATEGORY_ERROR:
      return { ...state, loading: false, keywordUnderCategoryResposnse: null }

    case GET_ALL_KEYWORDS:
      return { ...state, loading: true }
    case GET_ALL_KEYWORDS_SUCCESS:
      return { ...state, loading: false, allKeywords: action.payload.response.message }
    case GET_ALL_KEYWORDS_ERROR:
      return { ...state, loading: false, allKeywords: null }

    case GET_DOWNLOAD_KEYWORD_CHART:
      return { ...state, loading: true }
    case GET_DOWNLOAD_KEYWORD_CHART_SUCCESS:
      return { ...state, loading: false, keywordChartDownloadResponse: action.payload.response.message }
    case GET_DOWNLOAD_KEYWORD_CHART_ERROR:
      return { ...state, loading: false, keywordChartDownloadResponse: false }

    default:
      return { ...state };
  }
}
export default Dashboard;