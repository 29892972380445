//AUTH
export const CHECK_ACCESS_TOKEN = 'CHECK_ACCESS_TOKEN'; // 엑세스 토큰 만료 확인
export const CHECK_ACCESS_TOKEN_SUCCESS = 'CHECK_ACCESS_TOKEN_SUCCESS';
export const CHECK_ACCESS_TOKEN_ERROR = 'CHECK_ACCESS_TOKEN_ERROR';

export const OAUTH_AUTHORIZE = 'OAUTH_AUTHORIZE'; //인증코드 가져오기
export const OAUTH_AUTHORIZE_SUCCESS = 'OAUTH_AUTHORIZE_SUCCESS';
export const OAUTH_AUTHORIZE_ERROR = 'OAUTH_AUTHORIZE_ERROR';

export const GET_OAUTH_TOKEN = 'GET_OAUTH_TOKEN'; //엑세스 토큰 가져오기
export const GET_OAUTH_TOKEN_SUCCESS = 'GET_OAUTH_TOKEN_SUCCESS';
export const GET_OAUTH_TOKEN_ERROR = 'GET_OAUTH_TOKEN_ERROR';

export const GET_REFRESH_OAUTH_TOKEN = 'GET_REFRESH_OAUTH_TOKEN'; //리프레쉬 토큰 가져오기
export const GET_REFRESH_OAUTH_TOKEN_SUCCESS = 'GET_REFRESH_OAUTH_TOKEN_SUCCESS'; //리프레쉬 토큰 가져오기
export const GET_REFRESH_OAUTH_TOKEN_ERROR = 'GET_REFRESH_OAUTH_TOKEN_ERROR'; //리프레쉬 토큰 가져오기

export const SET_NAVIBAR_SHOW = 'SET_NAVIBAR_SHOW'; //하단 네비바 보여주기


export const SET_SALES_GB = 'SET_SALES_GB'; //일지 구분 SET


// export const TOKEN_EXPIRED = 'TOKEN_EXPIRED';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';//비밀번호 변경
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_ERROR = 'CHANGE_PASSWORD_ERROR';

export const FIND_PASSWORD = 'FIND_PASSWORD'; // 비밀번호 찾기
export const FIND_PASSWORD_SUCCESS = 'FIND_PASSWORD_SUCCESS';
export const FIND_PASSWORD_ERROR = 'FIND_PASSWORD_ERROR';

export const POST_AUTHNUMBER = 'POST_AUTHNUMBER'; //인증번호 가져오기
export const POST_AUTHNUMBER_SUCCESS = 'POST_AUTHNUMBER_SUCCESS';
export const POST_AUTHNUMBER_ERROR = 'POST_AUTHNUMBER_ERROR';

export const POST_REGISTRATION = 'POST_REGISTRATION'; //신규 회원가입
export const POST_REGISTRATION_SUCCESS = 'POST_REGISTRATION_SUCCESS';
export const POST_REGISTRATION_ERROR = 'POST_REGISTRATION_ERROR';

export const POST_INVITE = 'POST_INVITE'; //회원 초대메일
export const POST_INVITE_SUCCESS = 'POST_INVITE_SUCCESS';
export const POST_INVITE_ERROR = 'POST_INVITE_ERROR';

export const POST_INVITE_REGISTRATION = 'POST_INVITE_REGISTRATION'; //초대 했을 때의 회원가입
export const POST_INVITE_REGISTRATION_SUCCESS = 'POST_INVITE_REGISTRATION_SUCCESS';
export const POST_INVITE_REGISTRATION_ERROR = 'POST_INVITE_REGISTRATION_ERROR';

export const POST_WORKGROUP = 'POST_WORKGROUP'; //워크그룹 생성
export const POST_WORKGROUP_SUCCESS = 'POST_WORKGROUP_SUCCESS';
export const POST_WORKGROUP_ERROR = 'POST_WORKGROUP_ERROR';

export const POST_CHECK_IS_REGISTERED = 'POST_CHECK_IS_REGISTERED'
export const POST_CHECK_IS_REGISTERED_SUCCESS = 'POST_CHECK_IS_REGISTERED_SUCCESS'
export const POST_CHECK_IS_REGISTERED_ERROR = 'POST_CHECK_IS_REGISTERED_ERROR'


/* SALESLOG */
export const POST_TEMPORARY_SALESLOG = 'POST_TEMPORARY_SALESLOG';   //영업일지 임시저장 등록
export const POST_TEMPORARY_SALESLOG_SUCCESS = 'POST_TEMPORARY_SALESLOG_SUCCESS';
export const POST_TEMPORARY_SALESLOG_ERROR = 'POST_TEMPORARY_SALESLOG_ERROR';

export const POST_AUTO_SALESLOG = 'POST_AUTO_SALESLOG';   //자동 임시저장 등록
export const POST_AUTO_SALESLOG_SUCCESS = 'POST_AUTO_SALESLOG_SUCCESS';
export const POST_AUTO_SALESLOG_ERROR = 'POST_AUTO_SALESLOG_ERROR';

export const POST_SALESLOG = 'POST_SALESLOG';   //영업일지 등록
export const POST_SALESLOG_SUCCESS = 'POST_SALESLOG_SUCCESS';
export const POST_SALESLOG_ERROR = 'POST_SALESLOG_ERROR';

export const POST_SALESLOG_BATCH_ASYNC = 'POST_SALESLOG_BATCH_ASYNC';
export const POST_SALESLOG_BATCH_STATUS = 'POST_SALESLOG_BATCH_STATUS';

export const PUT_SALESLOG = 'PUT_SALESLOG'; //영업일지 수정
export const PUT_SALESLOG_SUCCESS = 'PUT_SALESLOG_SUCCESS';
export const PUT_SALESLOG_ERROR = 'PUT_SALESLOG_ERROR';

export const DELETE_SALESLOG = 'DELETE_SALESLOG';   //영업일지 삭제
export const DELETE_SALESLOG_SUCCESS = 'DELETE_SALESLOG_SUCCESS';
export const DELETE_SALESLOG_ERROR = 'DELETE_SALESLOG_ERROR';

export const SEARCH_SALESLOG_LIST = 'SEARCH_SALESLOG'; //영업일지 검색
export const SEARCH_SALESLOG_LIST_SUCCESS = 'SEARCH_SALESLOG_SUCCESS';
export const SEARCH_SALESLOG_LIST_ERROR = 'SEARCH_SALESLOG_ERROR';

export const GET_SALESLOG = 'GET_SALESLOG';  //영업일지 상세
export const GET_SALESLOG_SUCCESS = 'GET_SALESLOG_SUCCESS';
export const GET_SALESLOG_ERROR = 'GET_SALESLOG_ERROR';

export const GET_SALESLOGS = 'GET_SALESLOGS'; // 영업일지 리스트들 불러오기(백엔드 작업 안된듯 확인 필요)
export const GET_SALESLOGS_SUCCESS = 'GET_SALESLOGS_SUCCESS';
export const GET_SALESLOGS_ERROR = 'GET_SALESLOGS_ERROR';

export const GET_TEMPORARY_LISTS = 'GET_TEMPORARY_LISTS';  //임시 일지 리스트
export const GET_TEMPORARY_LISTS_SUCCESS = 'GET_TEMPORARY_LISTS_SUCCESS';
export const GET_TEMPORARY_LISTS_ERROR = 'GET_TEMPORARY_LISTS_ERROR';

export const GET_TEMPORARY_LIST = 'GET_TEMPORARY_LIST';  //임시 일지 상세
export const GET_TEMPORARY_LIST_SUCCESS = 'GET_TEMPORARY_LIST_SUCCESS';
export const GET_TEMPORARY_LIST_ERROR = 'GET_TEMPORARY_LIST_ERROR';

export const DELETE_TEMPORARY_LOG = 'DELETE_TEMPORARY_LOG';  //임시 일지 삭제
export const DELETE_TEMPORARY_LOG_SUCCESS = 'DELETE_TEMPORARY_LOG_SUCCESS';
export const DELETE_TEMPORARY_LOG_ERROR = 'DELETE_TEMPORARY_LOG_ERROR';

export const UPLOAD_FILE = 'UPLOAD_FILE'; // 파일 업로드
export const UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS';
export const UPLOAD_FILE_ERROR = 'UPLOAD_FILE_ERROR';

export const PUT_FILE = 'UPLOAD_FILE'; // 파일 수정
export const PUT_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS';
export const PUT_FILE_ERROR = 'UPLOAD_FILE_ERROR';

export const DELETE_FILE = 'DELETE_FILE'; // 파일 삭제
export const DELETE_FILE_SUCCESS = 'DELETE_FILE_SUCCESS';
export const DELETE_FILE_ERROR = 'DELETE_FILE_ERROR';

export const SELECT_USER_LIST = 'SELECT_USER_LIST'; // 사용자 검색
export const SELECT_USER_LIST_SUCCESS = 'SELECT_USER_LIST_SUCCESS';
export const SELECT_USER_LIST_ERROR = 'SELECT_USER_LIST_ERROR';

export const PUT_COUSER = 'PUT_COUSER';  // 공동작성자 추가
export const PUT_COUSER_SUCCESS = 'PUT_COUSER_SUCCESS';
export const PUT_COUSER_ERROR = 'PUT_COUSER_ERROR';

export const DELETE_COUSER = 'DELETE_COUSER';  // 공동작성자 삭제
export const DELETE_COUSER_SUCCESS = 'DELETE_COUSER_SUCCESS';
export const DELETE_COUSER_ERROR = 'DELETE_COUSER_ERROR';

export const CLEAR_SALESLOG = 'CLEAR_SALESLOG';
export const CLEAR_TEMP_LOG = 'CLEAR_TEMP_LOG';

//데이터 저장
export const STORE_DATA = 'STORE_DATA';



// 피드백
export const POST_COMMENT = 'POST_COMMENT'; // 피드백 등록
export const POST_COMMENT_SUCCESS = 'POST_COMMENT_SUCCESS';
export const POST_COMMENT_ERROR = 'POST_COMMENT_ERROR';

export const PUT_COMMENT = 'PUT_COMMENT'; // 피드백 수정
export const PUT_COMMENT_SUCCESS = 'PUT_COMMENT_SUCCESS';
export const PUT_COMMENT_ERROR = 'PUT_COMMENT_ERROR';

export const DELETE_COMMENT = 'DELETE_COMMENT'; // 피드백 삭제
export const DELETE_COMMENT_SUCCESS = 'DELETE_COMMENT_SUCCESS';
export const DELETE_COMMENT_ERROR = 'DELETE_COMMENT_ERROR';

export const GET_COMMENT_LISTS = 'GET_COMMENT_LISTS'; // 피드백 리스트
export const GET_COMMENT_LISTS_SUCCESS = 'GET_COMMENT_LISTS_SUCCESS';
export const GET_COMMENT_LISTS_ERROR = 'GET_COMMENT_LISTS_ERROR';


/* ACCOUNT  */
export const SELECT_ACCOUNTS = 'SELECT_ACCOUNT';  // 고객사 선택
export const SELECT_ACCOUNTS_SUCCESS = 'SELECT_ACCOUNT_SUCCESS';
export const SELECT_ACCOUNTS_ERROR = 'SELECT_ACCOUNT_ERROR';
export const POST_ACCOUNTS = 'POST_ACCOUNTS';  // 고객사 등록
export const POST_ACCOUNTS_SUCCESS = 'POST_ACCOUNTS_SUCCESS';
export const POST_ACCOUNTS_ERROR = 'POST_ACCOUNTS_ERROR';
export const PUT_ACCOUNTS = 'PUT_ACCOUNTS';  // 고객사 수정
export const PUT_ACCOUNTS_SUCCESS = 'PUT_ACCOUNTS_SUCCESS';
export const PUT_ACCOUNTS_ERROR = 'PUT_ACCOUNTS_ERROR';
export const SELECT_ACCOUNT_PERSON = 'SELECT_ACCOUNT_PERSON';  //고객 담당자 선택
export const SELECT_ACCOUNT_PERSON_SUCCESS = 'SELECT_ACCOUNT_PERSON_SUCCESS';
export const SELECT_ACCOUNT_PERSON_ERROR = 'SELECT_ACCOUNT_PERSON_ERROR';
export const POST_ACCOUNT_PERSON = 'POST_ACCOUNT_PERSON';  //고객 담당자 등록
export const POST_ACCOUNT_PERSON_SUCCESS = 'POST_ACCOUNT_PERSON_SUCCESS';
export const POST_ACCOUNT_PERSON_ERROR = 'POST_ACCOUNT_PERSON_ERROR';
export const PUT_ACCOUNT_PERSON = 'PUT_ACCOUNT_PERSON';  //고객 담당자 수정
export const PUT_ACCOUNT_PERSON_SUCCESS = 'PUT_ACCOUNT_PERSON_SUCCESS';
export const PUT_ACCOUNT_PERSON_ERROR = 'PUT_ACCOUNT_PERSON_ERROR';

//customers
//고객사 등록
export const POST_CUSTOMER = 'POST_CUSTOMER'
export const POST_CUSTOMER_SUCCESS = 'POST_CUSTOMER_SUCCESS'
export const POST_CUSTOMER_ERROR = 'POST_CUSTOMER_ERROR'
//고객사 리스트 가져오기 
export const GET_CUSTOMER = 'GET_CUSTOMER'
export const GET_CUSTOMER_SUCCESS = 'GET_CUSTOMER_SUCCESS'
export const GET_CUSTOMER_ERROR = 'GET_CUSTOMER_ERROR'
//사원필터링 가져오기 
export const GET_CUSTOMER_OPTION_USERS = 'GET_CUSTOMER_OPTION_USERS'
export const GET_CUSTOMER_OPTION_USERS_SUCCESS = 'GET_CUSTOMER_OPTION_USERS_SUCCESS'
export const GET_CUSTOMER_OPTION_USERS_ERROR = 'GET_CUSTOMER_OPTION_USERS_ERROR'
//고객사 상세 
export const GET_CUSTOMER_DETAILS = 'GET_CUSTOMER_DETAILS'
export const GET_CUSTOMER_DETAILS_SUCCESS = 'GET_CUSTOMER_DETAILS_SUCCESS'
export const GET_CUSTOMER_DETAILS_ERROR = 'GET_CUSTOMER_DETAILS_ERROR'
//고객사 수정
export const POST_EDIT_CUSTOMER = 'POST_EDIT_CUSTOMER'
export const POST_EDIT_CUSTOMER_SUCCESS = 'POST_EDIT_CUSTOMER_SUCCESS'
export const POST_EDIT_CUSTOMER_ERROR = 'POST_EDIT_CUSTOMER_ERROR'
//고객사 삭제
export const DEL_CUSTOMER = 'DEL_CUSTOMER'
export const DEL_CUSTOMER_SUCCESS = 'DEL_CUSTOMER_SUCCESS'
export const DEL_CUSTOMER_ERROR = 'DEL_CUSTOMER_ERROR'
//당당자 가져오기 
export const GET_MANAGER_INFO = 'GET_MANAGER_INFO'
export const GET_MANAGER_INFO_SUCCESS = 'GET_MANAGER_INFO_SUCCESS'
export const GET_MANAGER_INFO_ERROR = 'GET_MANAGER_INFO_ERROR'
//담당자 등록
export const POST_CUSTOMER_MANAGER = 'POST_CUSTOMER_MANAGER'
export const POST_CUSTOMER_MANAGER_SUCCESS = 'POST_CUSTOMER_MANAGER_SUCCESS'
export const POST_CUSTOMER_MANAGER_ERROR = 'POST_CUSTOMER_MANAGER_ERROR'
//담당자 수정
export const POST_EDIT_MANAGER_INFO = 'POST_EDIT_MANAGER_INFO'
export const POST_EDIT_MANAGER_INFO_SUCCESS = 'POST_EDIT_MANAGER_INFO_SUCCESS'
export const POST_EDIT_MANAGER_INFO_ERROR = 'POST_EDIT_MANAGER_INFO_ERROR'
//담당자 삭제
export const DEL_CUSTOMER_MANAGER = 'DEL_CUSTOMER_MANAGER'
export const DEL_CUSTOMER_MANAGER_SUCCESS = 'DEL_CUSTOMER_MANAGER_SUCCESS'
export const DEL_CUSTOMER_MANAGER_ERROR = 'DEL_CUSTOMER_MANAGER_ERROR'
//명함 사진 수정
export const POST_EDIT_NAMECARD = 'POST_EDIT_NAMECARD'
export const POST_EDIT_NAMECARD_SUCCESS = 'POST_EDIT_NAMECARD_SUCCESS'
export const POST_EDIT_NAMECARD_ERROR = 'POST_EDIT_NAMECARD_ERROR'

//고객사 프로필 일지 탭
export const SET_LAST_TAB = 'SET_LAST_TAB'

//고객 리스트 전체, 거래고객, 리드고객 탭
export const SET_ACCOUNT_TAB = 'SET_ACCOUNT_TAB'

//고객사 프로필 엑셀 업로드
export const POST_ACC_FILE = 'POST_ACC_FILE'
export const POST_ACC_FILE_SUCCESS = 'POST_ACC_FILE_SUCCESS'
export const POST_ACC_FILE_ERROR = 'POST_ACC_FILE_ERROR'

export const GET_NEWS_NAVER = 'GET_NEWS_NAVER'
export const GET_NEWS_NAVER_SUCCESS = 'GET_NEWS_NAVER_SUCCESS'
export const GET_NEWS_NAVER_ERROR = 'GET_NEWS_NAVER_ERROR'

export const GET_NEWS_DAUM = 'GET_NEWS_DAUM'
export const GET_NEWS_DAUM_SUCCESS = 'GET_NEWS_DAUM_SUCCESS'
export const GET_NEWS_DAUM_ERROR = 'GET_NEWS_DAUM_ERROR'

export const GET_NEWS_GOOGLE = 'GET_NEWS_GOOGLE'
export const GET_NEWS_GOOGLE_SUCCESS = 'GET_NEWS_GOOGLE_SUCCESS'
export const GET_NEWS_GOOGLE_ERROR = 'GET_NEWS_GOOGLE_ERROR'

export const GET_CUSTOMER_NEEDS = 'GET_CUSTOMER_NEEDS'
export const GET_CUSTOMER_NEEDS_SUCCESS = 'GET_CUSTOMER_NEEDS_SUCCESS'
export const GET_CUSTOMER_NEEDS_ERROR = 'GET_CUSTOMER_NEEDS_ERROR'

export const GET_CUSTOMER_FEEDBACK = 'GET_CUSTOMER_FEEDBACK'
export const GET_CUSTOMER_FEEDBACK_SUCCESS = 'GET_CUSTOMER_FEEDBACK_SUCCESS'
export const GET_CUSTOMER_FEEDBACK_ERROR = 'GET_CUSTOMER_FEEDBACK_ERROR'

export const GET_CUSTOMER_LOGS = 'GET_CUSTOMER_LOGS'
export const GET_CUSTOMER_LOGS_SUCCESS = 'GET_CUSTOMER_LOGS_SUCCESS'
export const GET_CUSTOMER_LOGS_ERROR = 'GET_CUSTOMER_LOGS_ERROR'

//workgroup

// 워크그룹 멤버 2명된 생성 날짜
export const GET_WORKGROUP_CRE = 'GET_WORKGROUP_CRE'
export const GET_WORKGROUP_CRE_SUCCESS = 'GET_WORKGROUP_CRE_SUCCESS'
export const GET_WORKGROUP_CRE_ERROR = 'GET_WORKGROUP_CRE_ERROR'
//워크그룹 로고등록
export const POST_WORKGROUP_LOGO = 'POST_WORKGROUP_LOGO'
export const POST_WORKGROUP_LOGO_SUCCESS = 'POST_WORKGROUP_LOGO_SUCCESS'
export const POST_WORKGROUP_LOGO_ERROR = 'POST_WORKGROUP_LOGO_ERROR'
//워크그룹 상세
export const GET_WORKGROUP_INFO = 'GET_WORKGROUP_INFO'
export const GET_WORKGROUP_INFO_SUCCESS = 'GET_WORKGROUP_INFO_SUCCESS'
export const GET_WORKGROUP_INFO_ERROR = 'GET_WORKGROUP_INFO_ERROR'
//워크그룹 수정
export const POST_WORKGROUP_UPD = 'POST_WORKGROUP_UPD'
export const POST_WORKGROUP_UPD_SUCCESS = 'POST_WORKGROUP_UPD_SUCCESS'
export const POST_WORKGROUP_UPD_ERROR = 'POST_WORKGROUP_UPD_ERROR'
//워크그룹 생성
export const POST_WORKGROUP_REGI = 'POST_WORKGROUP_REGI'
export const POST_WORKGROUP_REGI_SUCCESS = 'POST_WORKGROUP_REGI_SUCCESS'
export const POST_WORKGROUP_REGI_ERROR = 'POST_WORKGROUP_REGI_ERROR'
//워크그룹 리스트
export const GET_WORKGROUP_LIST = 'GET_WORKGROUP_LIST'
export const GET_WORKGROUP_LIST_SUCCESS = 'GET_WORKGROUP_LIST_SUCCESS'
export const GET_WORKGROUP_LIST_ERROR = 'GET_WORKGROUP_LIST_ERROR'
//워크그룹 변경
export const POST_WORKGROUP_CHANGE = 'POST_WORKGROUP_CHANGE'
export const POST_WORKGROUP_CHANGE_SUCCESS = 'POST_WORKGROUP_CHANGE_SUCCESS'
export const POST_WORKGROUP_CHANGE_ERROR = 'POST_WORKGROUP_CHANGE_ERROR'
//워크그룹 나가기
export const POST_WORKGROUP_OUT = 'POST_WORKGROUP_OUT'
export const POST_WORKGROUP_OUT_SUCCESS = 'POST_WORKGROUP_OUT_SUCCESS'
export const POST_WORKGROUP_OUT_ERROR = 'POST_WORKGROUP_OUT_ERROR'
//워크그룹 삭제
export const POST_WORKGROUP_DEL = 'POST_WORKGROUP_DEL'
export const POST_WORKGROUP_DEL_SUCCESS = 'POST_WORKGROUP_DEL_SUCCESS'
export const POST_WORKGROUP_DEL_ERROR = 'POST_WORKGROUP_DEL_ERROR'
//워크그룹 맴버
export const GET_GROUP_MEMBER_LIST = 'GET_GROUP_MEMBER_LIST'
export const GET_GROUP_MEMBER_LIST_SUCCESS = 'GET_GROUP_MEMBER_LIST_SUCCESS'
export const GET_GROUP_MEMBER_LIST_ERROR = 'GET_GROUP_MEMBER_LIST_ERROR'
//워크그룹 맴버 상세
export const GET_GROUP_MEMBER_DETAIL = 'GET_GROUP_MEMBER_DETAIL'
export const GET_GROUP_MEMBER_DETAIL_SUCCESS = 'GET_GROUP_MEMBER_DETAIL_SUCCESS'
export const GET_GROUP_MEMBER_DETAIL_ERROR = 'GET_GROUP_MEMBER_DETAIL_ERROR'
//워크그룹 맴버 수정
export const POST_GROUP_MEMBER_UPD = 'POST_GROUP_MEMBER_UPD'
export const POST_GROUP_MEMBER_UPD_SUCCESS = 'POST_GROUP_MEMBER_UPD_SUCCESS'
export const POST_GROUP_MEMBER_UPD_ERROR = 'POST_GROUP_MEMBER_UPD_ERROR'

//워크그룹 맴버 내보내기
export const POST_GROUP_MEMBER_OUT = 'POST_GROUP_MEMBER_OUT'
export const POST_GROUP_MEMBER_OUT_SUCCESS = 'POST_GROUP_MEMBER_OUT_SUCCESS'
export const POST_GROUP_MEMBER_OUT_ERROR = 'POST_GROUP_MEMBER_OUT_ERROR'

//내프로필정보 
export const GET_PROFILE_INFO = 'GET_PROFILE_INFO'
export const GET_PROFILE_INFO_SUCCESS = 'GET_PROFILE_INFO_SUCCESS'
export const GET_PROFILE_INFO_ERROR = 'GET_PROFILE_INFO_ERROR'

//워크그룹 초대
export const POST_GROUP_INVITE = 'POST_GROUP_INVITE'
export const POST_GROUP_INVITE_SUCCESS = 'POST_GROUP_INVITE_SUCCESS'
export const POST_GROUP_INVITE_ERROR = 'POST_GROUP_INVITE_ERROR'

//워크그룹 초대 리스트
export const GET_INVITE_LIST = 'GET_INVITE_LIST'
export const GET_INVITE_LIST_SUCCESS = 'GET_INVITE_LIST_SUCCESS'
export const GET_INVITE_LIST_ERROR = 'GET_INVITE_LIST_ERROR'

//워크그룹 초대 취소
export const POST_INVITE_DEL = 'POST_INVITE_DEL'
export const POST_INVITE_DEL_SUCCESS = 'POST_INVITE_DEL_SUCCESS'
export const POST_INVITE_DEL_ERROR = 'POST_INVITE_DEL_ERROR'



//워크그룹 부서 가져오기
export const GET_DEPT_INFO = 'GET_DEPT_INFO'
export const GET_DEPT_INFO_SUCCESS = 'GET_DEPT_INFO_SUCCESS'
export const GET_DEPT_INFO_ERROR = 'GET_DEPT_INFO_ERROR'
//워크그룹 부서 등록
export const POST_DEPT_REGI = 'POST_DEPT_REGI'
export const POST_DEPT_REGI_SUCCESS = 'POST_DEPT_REGI_SUCCESS'
export const POST_DEPT_REGI_ERROR = 'POST_DEPT_REGI_ERROR'
//워크그룹 부서 수정
export const POST_DEPT_UPD = 'POST_DEPT_UPD'
export const POST_DEPT_UPD_SUCCESS = 'POST_DEPT_UPD_SUCCESS'
export const POST_DEPT_UPD_ERROR = 'POST_DEPT_UPD_ERROR'
//워크그룹 부서 삭제
export const POST_DEPT_DEL = 'POST_DEPT_DEL'
export const POST_DEPT_DEL_SUCCESS = 'POST_DEPT_DEL_SUCCESS'
export const POST_DEPT_DEL_ERROR = 'POST_DEPT_DEL_ERROR'


//etc 프로파일 보기
export const GET_PROFILE_DETAIL = 'GET_PROFILE_DETAIL'
export const GET_PROFILE_DETAIL_SUCCESS = 'GET_PROFILE_DETAIL_SUCCESS'
export const GET_PROFILE_DETAIL_ERROR = 'GET_PROFILE_DETAIL_ERROR'
//etc 프로파일 수정
export const POST_PROFILE_UPD = 'POST_PROFILE_UPD'
export const POST_PROFILE_UPD_SUCCESS = 'POST_PROFILE_UPD_SUCCESS'
export const POST_PROFILE_UPD_ERROR = 'POST_PROFILE_UPD_ERROR'
//etc 프로파일 사진변경
export const POST_PROFILE_PHOTO = 'POST_PROFILE_PHOTO'
export const POST_PROFILE_PHOTO_SUCCESS = 'POST_PROFILE_PHOTO_SUCCESS'
export const POST_PROFILE_PHOTO_ERROR = 'POST_PROFILE_PHOTO_ERROR'
//etc 워크그룹 공지 리스트
export const GET_NOTICE_GRP_LIST = 'GET_NOTICE_GRP_LIST'
export const GET_NOTICE_GRP_LIST_SUCCESS = 'GET_NOTICE_GRP_LIST_SUCCESS'
export const GET_NOTICE_GRP_LIST_ERROR = 'GET_NOTICE_GRP_LIST_ERROR'
//etc 워크그룹 공지 상세
export const GET_NOTICE_GRP_DETAIL = 'GET_NOTICE_GRP_DETAIL'
export const GET_NOTICE_GRP_DETAIL_SUCCESS = 'GET_NOTICE_GRP_DETAIL_SUCCESS'
export const GET_NOTICE_GRP_DETAIL_ERROR = 'GET_NOTICE_GRP_DETAIL_ERROR'
//etc 워크그룹 공지 수정
export const POST_NOTICE_GRP_UPD = 'POST_NOTICE_GRP_UPD'
export const POST_NOTICE_GRP_UPD_SUCCESS = 'POST_NOTICE_GRP_UPD_SUCCESS'
export const POST_NOTICE_GRP_UPD_ERROR = 'POST_NOTICE_GRP_UPD_ERROR'
//etc 워크그룹 공지 삭제 
export const POST_NOTICE_GRP_DEL = 'POST_NOTICE_GRP_DEL'
export const POST_NOTICE_GRP_DEL_SUCCESS = 'POST_NOTICE_GRP_DEL_SUCCESS'
export const POST_NOTICE_GRP_DEL_ERROR = 'POST_NOTICE_GRP_DEL_ERROR'
//etc 워크그룹 공지 등록
export const POST_NOTICE_GRP_REGI = 'POST_NOTICE_GRP_REGI'
export const POST_NOTICE_GRP_REGI_SUCCESS = 'POST_NOTICE_GRP_REGI_SUCCESS'
export const POST_NOTICE_GRP_REGI_ERROR = 'POST_NOTICE_GRP_REGI_ERROR'
//etc 시스템 공지 리스트
export const GET_NOTICE_SYS_LIST = 'GET_NOTICE_SYS_LIST'
export const GET_NOTICE_SYS_LIST_SUCCESS = 'GET_NOTICE_SYS_LIST_SUCCESS'
export const GET_NOTICE_SYS_LIST_ERROR = 'GET_NOTICE_SYS_LIST_ERROR'
//etc 시스템 공지 상세
export const GET_NOTICE_SYS_DETAIL = 'GET_NOTICE_SYS_DETAIL'
export const GET_NOTICE_SYS_DETAIL_SUCCESS = 'GET_NOTICE_SYS_DETAIL_SUCCESS'
export const GET_NOTICE_SYS_DETAIL_ERROR = 'GET_NOTICE_SYS_DETAIL_ERROR'
//etc 시스템 공지 수정
export const POST_NOTICE_SYS_UPD = 'POST_NOTICE_SYS_UPD'
export const POST_NOTICE_SYS_UPD_SUCCESS = 'POST_NOTICE_SYS_UPD_SUCCESS'
export const POST_NOTICE_SYS_UPD_ERROR = 'POST_NOTICE_SYS_UPD_ERROR'
//etc 시스템 공지 삭제
export const POST_NOTICE_SYS_DEL = 'POST_NOTICE_SYS_DEL'
export const POST_NOTICE_SYS_DEL_SUCCESS = 'POST_NOTICE_SYS_DEL_SUCCESS'
export const POST_NOTICE_SYS_DEL_ERROR = 'POST_NOTICE_SYS_DEL_ERROR'
//etc 시스템 공지 등록
export const POST_NOTICE_SYS_REGI = 'POST_NOTICE_SYS_REGI'
export const POST_NOTICE_SYS_REGI_SUCCESS = 'POST_NOTICE_SYS_REGI_SUCCESS'
export const POST_NOTICE_SYS_REGI_ERROR = 'POST_NOTICE_SYS_REGI_ERROR'

//오른쪽 메뉴 - 기념일 시스템 공지사항, 워크그룹 공지사항
export const POST_ANNIVERSARY = 'POST_ANNIVERSARY'
export const POST_ANNIVERSARY_SUCCESS = 'POST_ANNIVERSARY_SUCCESS'
export const POST_ANNIVERSARY_ERROR = 'POST_ANNIVERSARY_ERROR'

export const POST_SYSTEM_NOTICE = 'POST_SYSTEM_NOTICE'
export const POST_SYSTEM_NOTICE_SUCCESS = 'POST_SYSTEM_NOTICE_SUCCESS'
export const POST_SYSTEM_NOTICE_ERROR = 'POST_SYSTEM_NOTICE_ERROR'

export const POST_WORKGROUP_NOTICE = 'POST_WORKGROUP_NOTICE'
export const POST_WORKGROUP_NOTICE_SUCCESS = 'POST_WORKGROUP_NOTICE_SUCCESS'
export const POST_WORKGROUP_NOTICE_ERROR = 'POST_WORKGROUP_NOTICE_ERROR'

//부서 
export const GET_ORGANIZATION = 'GET_ORGANIZATION';
export const GET_ORGANIZATION_SUCCESS = 'GET_ORGANIZATION_SUCCESS';
export const GET_ORGANIZATION_ERROR = 'GET_ORGANIZATION_ERROR';

export const GET_ORGANIZATION_USER = 'GET_ORGANIZATION_USER';
export const GET_ORGANIZATION_USER_SUCCESS = 'GET_ORGANIZATION_USER_SUCCESS';
export const GET_ORGANIZATION_USER_ERROR = 'GET_ORGANIZATION_USER_ERROR';

export const GET_ORGANIZATION_DASH = 'GET_ORGANIZATION_DASH';
export const GET_ORGANIZATION_DASH_SUCCESS = 'GET_ORGANIZATION_DASH_SUCCESS';
export const GET_ORGANIZATION_DASH_ERROR = 'GET_ORGANIZATION_DASH_ERROR';

export const GET_ORGANIZATION_USER_DASH = 'GET_ORGANIZATION_USER_DASH';
export const GET_ORGANIZATION_USER_DASH_SUCCESS = 'GET_ORGANIZATION_USER_DASH_SUCCESS';
export const GET_ORGANIZATION_USER_DASH_ERROR = 'GET_ORGANIZATION_USER_DASH_ERROR';

//프로필
export const GET_PROFILE = 'GET_PROFILE';
export const GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS';
export const GET_PROFILE_ERROR = 'GET_PROFILE_ERROR';

export const GET_PROFILE_PICTURE = 'GET_PROFILE_PICTURE';
export const GET_PROFILE_PICTURE_SUCCESS = 'GET_PROFILE_PICTURE_SUCCESS';
export const GET_PROFILE_PICTURE_ERROR = 'GET_PROFILE_PICTURE_ERROR';


//지원 support
export const POST_SUPPORT_INQUIRY = 'POST_SUPPORT_INQUIRY'
export const POST_SUPPORT_INQUIRY_SUCCESS = 'POST_SUPPORT_INQUIRY_SUCCESS'
export const POST_SUPPORT_INQUIRY_ERROR = 'POST_SUPPORT_INQUIRY_ERROR'

export const GET_SUPPORT_INQUIRY_LISTS = 'GET_SUPPORT_INQUIRY_LISTS'
export const GET_SUPPORT_INQUIRY_LISTS_SUCCESS = 'GET_SUPPORT_INQUIRY_LISTS_SUCCESS'
export const GET_SUPPORT_INQUIRY_LISTS_ERROR = 'GET_SUPPORT_INQUIRY_LISTS_ERROR'

export const GET_SUPPORT_INQUIRY_DETAIL = 'GET_SUPPORT_INQUIRY_DETAIL'
export const GET_SUPPORT_INQUIRY_DETAIL_SUCCESS = 'GET_SUPPORT_INQUIRY_DETAIL_SUCCESS'
export const GET_SUPPORT_INQUIRY_DETAIL_ERROR = 'GET_SUPPORT_INQUIRY_DETAIL_ERROR'

export const POST_EDIT_INQUIRY = 'POST_EDIT_INQUIRY'
export const POST_EDIT_INQUIRY_SUCCESS = 'POST_EDIT_INQUIRY_SUCCESS'
export const POST_EDIT_INQUIRY_ERROR = 'POST_EDIT_INQUIRY_ERROR'

export const POST_DELETE_INQUIRY = 'POST_DELETE_INQUIRY'
export const POST_DELETE_INQUIRY_SUCCESS = 'POST_DELETE_INQUIRY_SUCCESS'
export const POST_DELETE_INQUIRY_ERROR = 'POST_DELETE_INQUIRY_ERROR'

//dashboard

export const GET_SALES_STAT = 'GET_SALES_STAT';
export const GET_SALES_STAT_SUCCESS = 'GET_SALES_STAT_SUCCESS';
export const GET_SALES_STAT_ERROR = 'GET_SALES_STAT_ERROR';

export const GET_LEAD_STAT = 'GET_LEAD_STAT';
export const GET_LEAD_STAT_SUCCESS = 'GET_LEAD_STAT_SUCCESS';
export const GET_LEAD_STAT_ERROR = 'GET_LEAD_STAT_ERROR';

export const GET_INST_CUST_DETAIL = 'GET_INST_CUST_DETAIL';
export const GET_INST_CUST_DETAIL_SUCCESS = 'GET_INST_CUST_DETAIL_SUCCESS';
export const GET_INST_CUST_DETAIL_ERROR = 'GET_INST_CUST_DETAIL_ERROR';

export const GET_INST_ACT_STAT = 'GET_INST_ACT_STAT';
export const GET_INST_ACT_STAT_SUCCESS = 'GET_INST_ACT_STAT_SUCCESS';
export const GET_INST_ACT_STAT_ERROR = 'GET_INST_ACT_STAT_ERROR';

export const GET_INST_CUST_STAT = 'GET_INST_CUST_STAT';
export const GET_INST_CUST_STAT_SUCCESS = 'GET_INST_CUST_STAT_SUCCESS';
export const GET_INST_CUST_STAT_ERROR = 'GET_INST_CUST_STAT_ERROR';

export const GET_LOGS_EXCEL_DOWNLOAD = 'GET_LOGS_EXCEL_DOWNLOAD';
export const GET_LOGS_EXCEL_DOWNLOAD_SUCCESS = 'GET_LOGS_EXCEL_DOWNLOAD_SUCCESS';
export const GET_LOGS_EXCEL_DOWNLOAD_ERROR = 'GET_LOGS_EXCEL_DOWNLOAD_ERROR';

export const GET_KEYWORD_BY_MONTHS = 'GET_KEYWORD_BY_MONTHS'
export const GET_KEYWORD_BY_MONTHS_SUCCESS = 'GET_KEYWORD_BY_MONTHS_SUCCESS'
export const GET_KEYWORD_BY_MONTHS_ERROR = 'GET_KEYWORD_BY_MONTHS_ERROR'

export const GET_KEYWORD_UNDER_CATEGORY = 'GET_KEYWORD_UNDER_CATEGORY'
export const GET_KEYWORD_UNDER_CATEGORY_SUCCESS = 'GET_KEYWORD_UNDER_CATEGORY_SUCCESS'
export const GET_KEYWORD_UNDER_CATEGORY_ERROR = 'GET_KEYWORD_UNDER_CATEGORY_ERROR'

export const GET_ALL_KEYWORDS = 'GET_ALL_KEYWORDS'
export const GET_ALL_KEYWORDS_SUCCESS = 'GET_ALL_KEYWORDS_SUCCESS'
export const GET_ALL_KEYWORDS_ERROR = 'GET_ALL_KEYWORDS_ERROR'

export const GET_DOWNLOAD_KEYWORD_CHART = 'GET_DOWNLOAD_KEYWORD_CHART'
export const GET_DOWNLOAD_KEYWORD_CHART_SUCCESS = 'GET_DOWNLOAD_KEYWORD_CHART_SUCCESS'
export const GET_DOWNLOAD_KEYWORD_CHART_ERROR = 'GET_DOWNLOAD_KEYWORD_CHART_ERROR'

//device token
export const POST_DTOKEN_REGI = 'POST_DTOKEN_REGI'
export const POST_DTOKEN_REGI_SUCCESS = 'POST_DTOKEN_REGI_SUCCESS'
export const POST_DTOKEN_REGI_ERROR = 'POST_DTOKEN_REGI_ERROR'



//기존 것
//ACCOUNT
export const GET_USER_ACCOUNT = 'GET_USER_ACCOUNT';
export const GET_USER_ACCOUNT_SUCCESS = 'GET_USER_ACCOUNT_SUCCESS';
export const GET_USER_ACCOUNT_ERROR = 'GET_USER_ACCOUNT_ERROR';

//최근검색어 
export const SET_KEYWORD = 'SET_KEYWORD';

//noti 
export const BADEGE_CONTENT = 'BADGE_CONTENT';
export const GET_NOTIFICATION = 'GET_NOTIFICATION'
export const GET_NOTIFICATION_SUCCESS = 'GET_NOTIFICATION_SUCCESS'
export const GET_NOTIFICATION_ERROR = 'GET_NOTIFICATION_ERROR'

export const POST_NOTIFICATION_SETTING = 'POST_NOTIFICATION_SETTING'
export const POST_NOTIFICATION_SETTING_SUCCESS = 'POST_NOTIFICATION_SETTING_SUCCESS'
export const POST_NOTIFICATION_SETTING_ERROR = 'POST_NOTIFICATION_SETTING_ERROR'
//noti badgecontent
export const POST_NOTIFICATION_BEDGE = 'POST_NOTIFICATION_BEDGE'
export const POST_NOTIFICATION_BEDGE_SUCCESS = 'POST_NOTIFICATION_BEDGE_SUCCESS'
export const POST_NOTIFICATION_BEDGE_ERROR = 'POST_NOTIFICATION_BEDGE_ERROR'


//달력

export const GET_CALENDAR_LISTS = 'GET_CALENDAR_LISTS';
export const GET_CALENDAR_LISTS_SUCCESS = 'GET_CALENDAR_LISTS_SUCCESS';
export const GET_CALENDAR_LISTS_ERROR = 'GET_CALENDAR_LISTS_ERROR';

export const GET_CALENDAR_LIST = 'GET_CALENDAR_LIST';
export const GET_CALENDAR_LIST_SUCCESS = 'GET_CALENDAR_LIST_SUCCESS';
export const GET_CALENDAR_LIST_ERROR = 'GET_CALENDAR_LIST_ERROR';

export const POST_CALENDAR = 'POST_CALENDAR';
export const POST_CALENDAR_SUCCESS = 'POST_CALENDAR_SUCCESS';
export const POST_CALENDAR_ERROR = 'POST_CALENDAR_ERROR';

export const PUT_CALENDAR = 'PUT_CALENDAR';
export const PUT_CALENDAR_SUCCESS = 'PUT_CALENDAR_SUCCESS';
export const PUT_CALENDAR_ERROR = 'PUT_CALENDAR_ERROR';

export const DELETE_CALENDAR = 'DELETE_CALENDAR';
export const DELETE_CALENDAR_SUCCESS = 'DELETE_CALENDAR_SUCCESS';
export const DELETE_CALENDAR_ERROR = 'DELETE_CALENDAR_ERROR';

//해당 slot 일정 클릭 action
export const CALENDAR_DATE = 'CALENDAR_DATE';

//일정 수정용 action
export const CALENDAR_EDIT_MODAL = 'CALENDAR_EDIT_MODAL';

//bottom Button plus click action
export const CALENDAR_PLUS = 'CALENDAR_PLUS';

//일정 back, next event 값 
export const CALENDAR_EVENT = 'CALENDAR_EVENT';

//니즈학습

export const NEEDS_TRAIN = 'NEEDS_TRAIN';
export const NEEDS_TRAIN_SUCCESS = 'NEEDS_TRAIN_SUCCESS';
export const NEEDS_TRAIN_ERROR = 'NEEDS_TRAIN_ERROR';

export const NEEDS_TRAIN_LISTS = 'NEEDS_TRAIN_LISTS';
export const NEEDS_TRAIN_LISTS_SUCCESS = 'NEEDS_TRAIN_LISTS_SUCCESS';
export const NEEDS_TRAIN_LISTS_ERROR = 'NEEDS_TRAIN_LISTS_ERROR';

export const NEEDS_TRAIN_DEFINE = 'NEEDS_TRAIN_DEFINE';
export const NEEDS_TRAIN_DEFINE_SUCCESS = 'NEEDS_TRAIN_DEFINE_SUCCESS';
export const NEEDS_TRAIN_DEFINE_ERROR = 'NEEDS_TRAIN_DEFINE_ERROR';

export const NEEDS_TRAIN_SAVE = 'NEEDS_TRAIN_SAVE';
export const NEEDS_TRAIN_SAVE_SUCCESS = 'NEEDS_TRAIN_SAVE_SUCCESS';
export const NEEDS_TRAIN_SAVE_ERROR = 'NEEDS_TRAIN_SAVE_ERROR';

//결제 내역 리스트

export const PAYMENT_LISTS = 'PAYMENT_LISTS';
export const PAYMENT_LISTS_SUCCESS = 'PAYMENT_LISTS_SUCCESS';
export const PAYMENT_LISTS_ERROR = 'PAYMENT_LISTS_ERROR';


// 키워드 알림 설정
export const GET_KEYWORD_LOG = 'GET_KEYWORD_LOG'
export const GET_KEYWORD_LOG_SUCCESS = 'GET_KEYWORD_LOG_SUCCESS'
export const GET_KEYWORD_LOG_ERROR = 'GET_KEYWORD_LOG_ERROR'

export const POST_KEYWORD_LOG_REGI = 'POST_KEYWORD_LOG'
export const POST_KEYWORD_LOG_REGI_SUCCESS = 'POST_KEYWORD_LOG_REGI_SUCCESS'
export const POST_KEYWORD_LOG_REGI_ERROR = 'POST_KEYWORD_LOG_REGI_ERROR'


