import {
  GET_SALES_STAT,
  GET_LEAD_STAT,
  GET_INST_CUST_DETAIL,
  GET_INST_ACT_STAT,
  GET_INST_CUST_STAT,
  GET_LOGS_EXCEL_DOWNLOAD,
  GET_KEYWORD_BY_MONTHS,
  GET_KEYWORD_UNDER_CATEGORY,
  GET_ALL_KEYWORDS,
  GET_DOWNLOAD_KEYWORD_CHART,
} from '../../constants/actionTypes';

import { fetchActionGenerator } from '../utils';

export const getsaleslogstat = fetchActionGenerator(GET_SALES_STAT, 'body');
export const getleadlogstat = fetchActionGenerator(GET_LEAD_STAT, 'body');
export const getInstCustDetail = fetchActionGenerator(GET_INST_CUST_DETAIL, 'body');
export const getInstActstat = fetchActionGenerator(GET_INST_ACT_STAT, 'body');
export const getInstCuststat = fetchActionGenerator(GET_INST_CUST_STAT, 'body');
export const getlogsdownload = fetchActionGenerator(GET_LOGS_EXCEL_DOWNLOAD, 'body');
export const getKeywordByMonths = fetchActionGenerator(GET_KEYWORD_BY_MONTHS, 'body')
export const getKeywordUnderCategory = fetchActionGenerator(GET_KEYWORD_UNDER_CATEGORY, 'body')
export const getAllKeywords = fetchActionGenerator(GET_ALL_KEYWORDS, 'body')
export const getKeywordChartDownload = fetchActionGenerator(GET_DOWNLOAD_KEYWORD_CHART, 'body')