import React from 'react';

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      error: null,
      errorInfo: null
    }
  }
  componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo
    })
  }

  render() {
    if (this.state.hasError) {
      return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <h2>알 수 없는 오류가 발생했습니다.</h2>
          <h4> </h4>
          <details>
            {this.setState.error && this.setState.error.toString()}
            <br />
          </details>
        </div>)
    }
    return this.props.children
  }
}